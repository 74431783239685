<template>
  <div id="chat2pf">
    <button @click="test">test</button>
    <beautiful-chat
        :participants="participants"
        :titleImageUrl="titleImageUrl"
        :onMessageWasSent="onMessageWasSent"
        :messageList="messageList"
        :newMessagesCount="newMessagesCount"
        :isOpen="isChatOpen"
        :close="closeChat"
        :icons="icons"
        :open="openChat"
        :showEmoji="true"
        :showFile="true"
        :showEdition="true"
        :showDeletion="true"
        :showTypingIndicator="showTypingIndicator"
        :showLauncher="true"
        :showCloseButton="true"
        :colors="colors"
        :alwaysScrollToBottom="alwaysScrollToBottom"
        :messageStyling="messageStyling"
        @onType="handleOnType"
        @edit="editMessage"/>
  </div>
</template>

<script>
import CloseIcon from './assets/close-icon.png'
import OpenIcon from './assets/logo-no-bg.svg'
import FileIcon from './assets/file.svg'
import CloseIconSvg from './assets/close.svg'
import fingerprint from './wasm_fingerprint_mod'

export default {

  name: 'App',

  data() {
    return {
      apiUrl: './chat2pf/api.php',
      fp: {},
      fingerprint: '',
      chatInterval: null,
      icons: {
        open: {
          img: OpenIcon,
          name: 'default',
        },
        close: {
          img: CloseIcon,
          name: 'default',
        },
        file: {
          img: FileIcon,
          name: 'default',
        },
        closeSvg: {
          img: CloseIconSvg,
          name: 'default',
        },
      },
      participants: [
        {
          id: 'user1',
          name: 'Support',
          imageUrl: 'https://avatars3.githubusercontent.com/u/37018832?s=200&v=4'
        }
      ], // the list of all the participant of the conversation. `name` is the user name, `id` is used to establish the author of a message, `imageUrl` is supposed to be the user avatar.
      titleImageUrl: 'https://a.slack-edge.com/66f9/img/avatars-teams/ava_0001-34.png',
      messageList: [], // the list of the messages to show, can be paginated and adjusted dynamically
      newMessagesCount: 0,
      isChatOpen: true, // to determine whether the chat window should be open or closed
      showTypingIndicator: '', // when set to a value matching the participant.id it shows the typing indicator for the specific user
      colors: {
        header: {
          bg: '#4e8cff',
          text: '#ffffff'
        },
        launcher: {
          bg: '#4e8cff'
        },
        messageList: {
          bg: '#ffffff'
        },
        sentMessage: {
          bg: '#4e8cff',
          text: '#ffffff'
        },
        receivedMessage: {
          bg: '#eaeaea',
          text: '#222222'
        },
        userInput: {
          bg: '#f4f7f9',
          text: '#565867'
        }
      }, // specifies the color scheme for the component
      alwaysScrollToBottom: false, // when set to true always scrolls the chat to the bottom when new events are in (new message, user starts typing...)
      messageStyling: true // enables *bold* /emph/ _underline_ and such (more info at github.com/mattezza/msgdown)
    }
  },

  created() {
    this.fp = new fingerprint();

    const interval = setInterval(() => {
      if (typeof this.fp.make_fingerprint == 'function') {
        clearInterval(interval);
        this.fingerprint = this.fp.make_fingerprint()['print'];
      }
    }, 100);
  },

  watch: {
    fingerprint(v) {
      if (v.length > 0 && this.chatInterval === null) {
        this.getChat();
        this.chatInterval = setInterval(() => {
          this.getChat();
        }, 3000);
      }
    }
  },

  methods: {
    test() {
      console.log(this.isChatOpen);
    },
    getChat() {
      this.$axios.post(this.apiUrl, {
        action: 'getChat',
        fingerprint: this.fingerprint
      }).then(response => {
        if (response.data.status) {
          if (!this.isChatOpen)
            this.newMessagesCount = this.newMessagesCount + response.data.chat.messageList.length - this.messageList.length
          this.messageList = response.data.chat.messageList
        } else {
          console.error(response.data.message);
        }
      });
    },
    sendMessage(text) {
      if (text.length > 0) {
        this.newMessagesCount = this.isChatOpen ? this.newMessagesCount : this.newMessagesCount + 1
        this.onMessageWasSent({author: 'support', type: 'text', data: {text}})
      }
    },
    onMessageWasSent(message) {
      // called when the user sends a message

      if (message.type === 'file') {
        console.log(message.data.file);
        console.log(message.data.file.File);
        this.$axios.post(this.apiUrl, {
          action: 'uploadFile',
          fingerprint: this.fingerprint,
          file: message.data.file
        }).then(response => {
          if (response.data.status) {
            console.log(response);
          } else {
            console.error(response.data.message);
          }
        })
      }

      if (message.type === 'text') {
        this.$axios.post(this.apiUrl, {
          action: 'sendMessage',
          fingerprint: this.fingerprint,
          message: message
        }).then(response => {
          if (response.data.status) {
            this.messageList = [...this.messageList, message]
          } else {
            console.error(response.data.message);
          }
        });
      }

    },
    openChat() {
      // called when the user clicks on the fab button to open the chat
      this.isChatOpen = true
      this.newMessagesCount = 0
    },
    closeChat() {
      // called when the user clicks on the botton to close the chat
      this.isChatOpen = false
    },
    handleScrollToTop() {
      // called when the user scrolls message list to top
      // leverage pagination for loading another page of messages
    },
    handleOnType() {
      //
    },
    editMessage(message) {
      const m = this.messageList.find(m => m.id === message.id);
      m.isEdited = true;
      m.data.text = message.data.text;
    }
  }

}
</script>